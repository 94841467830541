document.addEventListener('turbolinks:load', function() {
  if ($(".payment_methods").length) {
    if (!$(".payment_methods input:radio:checked").length) {
      $(".payment_methods input:radio:first").prop("checked", true);
    }
    $(".payment_plans-select input").change(function() {
      var el;
      el = $(this).data('vat') ? $(this) : $(".payment_plans-select #button_payment_plan_" + $(".payment_plans-select input").val());
      $('#vat').html(el.data('vat'));
      if (el.data('recurring')) {
        $(".payment_methods input:radio").first("[data-recurring=true]").prop("checked", true);
        return $(".payment_methods input:radio").not("[data-recurring=true]").prop("disabled", true).closest('div').fadeOut();
      } else {
        return $(".payment_methods input:radio").prop("disabled", false).closest('div').fadeIn();
      }
    });
    $(".payment_plans-select input:radio:checked").trigger('change');
  }
  $("#cancel_link").click(function() {
    $('#cancel_box').toggle();
    $(this).hide();
    return false;
  });
  return $('.password .input-group-append').click(function() {
    var el;
    el = $(this).prev('input');
    if (el.attr('type') === 'password') {
      return el.attr('type', 'text');
    } else {
      return el.attr('type', 'password');
    }
  });
});
