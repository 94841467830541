document.addEventListener('turbolinks:load', function() {
  var track_event;
  if (typeof gtag === 'undefined') {
    return;
  }
  track_event = function(el) {
    return gtag('event', el.data('trackAction'), {
      event_category: el.data('trackCategory'),
      event_label: el.data('trackLabel')
    });
  };
  $("a.gtag-track").on('click', function() {
    return track_event($(this));
  });
  if ($('div.gtag-track').length) {
    return track_event($('div.gtag-track'));
  }
});
