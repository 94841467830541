document.addEventListener('turbolinks:load', function() {
  if ($('.chocolat').length) {
    if (window.top !== window.self) {
      $('#chocolat-close').hide();
    }
    Chocolat(document.querySelectorAll('.chocolat a'), {
      container: document.querySelector('.chocolat-container'),
      setTitle: function() {
        return document.querySelector('.chocolat').dataset.chocolatTitle;
      }
    }).api.open();
    return $('.chocolat-close').on('click', function() {
      return Turbolinks.visit($('.chocolat').data('goto'));
    });
  }
});
