// $(window).on('turbolinks:load resize', function () {
document.addEventListener('turbolinks:load', function () {
    var header = document.querySelector("header");
    if (header !== null) {
        var headroom = new Headroom(header, {
            tolerance: {
                down: 0,
                up: 15
            },
            offset: {
                down: 200,
                up: 0
            },
            onUnpin: function () {
                $("body").removeClass("searchopen-navbar").addClass("searchhide-navbar");
            }
        });
        headroom.init();
    }
});

$(window).on('turbolinks:load resize', function () {
    if (!$('#navbarNav.show').length) {
        var header = $('header');
        if (
            $(window).width() < 992 ||
            $('body.sticky-header').length ||
            !$('body.skip-header, body.pages.index').length
        ) {
            $(".wrapper > .content").css("margin-top", header.innerHeight() + 'px');
            header.addClass('opacity-1');
        } else {
            $(".wrapper > .content").css("margin-top", '0px');
            header.removeClass('opacity-1');
        }
    }
});
