document.addEventListener('turbolinks:load', function() {
  if ($('.limit-height').length) {
    $('.limit-height').each(function() {
      if ($('.limit-height').height() > 200) {
        return $(this).addClass('active');
      }
    });
    return $('.show-more').click(function() {
      return $(this).closest('.limit-height.active').removeClass('active');
    });
  }
});
