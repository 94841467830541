document.addEventListener('turbolinks:load', function () {
    $('.adult-confirmation-no').click(function (e) {
        e.preventDefault();
        $('#adult-confirmation-1').hide();
        $('#adult-confirmation-2').show();
        return false
    });

    $('.adult-confirmation-yes').click(function (e) {
        e.preventDefault();
        Cookies.set('adult-confirmation', 1, {expires: 365});
        $('#adult-confirmation').hide();
        return false
    });
});
