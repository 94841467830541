document.addEventListener('turbolinks:load', function() {
  var executeLazyFunction, locale;
  window.lazyFunctions = {};
  executeLazyFunction = function(element) {
    var lazyFunction, lazyFunctionName;
    lazyFunctionName = element.getAttribute('data-lazy-function');
    lazyFunction = window.lazyFunctions[lazyFunctionName];
    if (!lazyFunction) {
      return;
    }
    return lazyFunction(element);
  };
  window.lazyLoadInstance = new LazyLoad({
    elements_selector: '.lazy',
    unobserve_entered: true,
    callback_enter: executeLazyFunction,
    threshold: 800
  });
  $('.copy-to-clipboard').on('click', function() {
    var element, input, replace_with;
    element = $(this);
    if (element.data('attr') === 'href') {
      input = this.appendChild(document.createElement("input"));
      input.value = $(element.data('copy')).attr('href');
      input.focus();
      input.select();
      document.execCommand('copy');
      input.parentNode.removeChild(input);
    } else {
      $(element.data('copy')).select();
      document.execCommand("copy");
    }
    replace_with = element.data('copy-replace-with');
    element.data('copy-replace-with', element.html());
    element.html(replace_with);
    element.focus();
    return setTimeout(function() {
      replace_with = element.data('copy-replace-with');
      element.data('copy-replace-with', element.html());
      return element.html(replace_with);
    }, 5000);
  });
  $('select#currency').on('change', function() {
    var u;
    u = new URL(window.location);
    u.searchParams.set('set-currency', $(this).val());
    return Turbolinks.visit(u.toString());
  });
  $('select#locale').on('change', function() {
    var u;
    u = $("option:selected", this).data('url');
    return Turbolinks.visit(u);
  });
  locale = navigator.language.split('-')[0];
  if (locale !== $('body').data('locale')) {
    $('#top-locale-' + locale).show();
  }
  return $('[data-toggle="tooltip-image"]').tooltip({
    template: '<div class="tooltip-image" role="tooltip"><div class="tooltip-inner"></div></div>'
  });
});

document.addEventListener('turbolinks:before-cache', function() {
  window.lazyLoadInstance.restoreAll();
  return window.lazyLoadInstance.destroy();
});
