global.$ = $; // So $ is available e.g. in app/assets/javascripts/themed/domains/www-shopmaker-com.js

require('@rails/ujs').start();
require('turbolinks').start();
require('bootstrap');
require('@hola.org/videojs-utils');
require('@silvermine/videojs-quality-selector')(videojs);
require('@silvermine/videojs-chromecast')(videojs, { preloadWebComponents: true });
// require('videojs-sprite-thumbnails');
require('justifiedGallery');
require('bootstrap-select');
Cookies = require('js-cookie');
LazyLoad = require('vanilla-lazyload');
Chocolat = require('chocolat').default;
twitter = require('twitter-text').default;

require('./100-layout.js');
require('./adult-confirmation.js');
require('./carts.js');
require('./collections.js');
require('./countdown.js');
require('./gtag.js');
require('./imagesets.js');
require('./subscriptions.js');
require('./videos.js');
