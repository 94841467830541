document.addEventListener('turbolinks:load', function () {
    $(".main-slider, .latest-videos-carousel, .latest-photos-carousel, .big-carousel-thumb, .thumbnail-carousel").slick({
        rows: 0, // https://github.com/kenwheeler/slick/issues/3207#issuecomment-342781958
        prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="icomoon icomoon-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="icomoon icomoon-arrow-right"></i></button>',
    }).slick('refresh');

    $(".full-screen-mode").on("click", function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $(this).closest('body').toggleClass('full-width');
        $('.slick-initialized').slick('resize');
    });

    $(window).on('resize orientationchange', function () {
        $('.slick-initialized').slick('resize');
    });

    btn = $(document).find('.toggle-slideshow');
    slideshow_started = false;
    $(document).on("click", ".toggle-slideshow", function () {
        if (slideshow_started) {
            slideshow_started = false
            $('.slick-initialized').slick('slickPause');
            btn.find('.text-btn').html(btn.data('text-start'))
        } else {
            slideshow_started = true
            $('.slick-initialized').slick('slickPlay');
            btn.find('.text-btn').html(btn.data('text-stop'))
        }
    });
});
