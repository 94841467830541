document.addEventListener('turbolinks:load', function () {
    var completedJoinSteps;
    if ($('#accordion .list-plans input:checked').length) {
        $("#collapseTwo").collapse('show');
        completedJoinSteps = 2;
    } else {
        completedJoinSteps = 1;
    }

    $('#accordion').on('show.bs.collapse', function (e) {
        $('#next-step').removeClass('active');

        if (completedJoinSteps >= $(e.target).data('step')) {
            return true
        } else {
            el = $('#heading-' + completedJoinSteps);
            el.addClass("highlight");
            setTimeout(function () {
                el.removeClass('highlight');
            }, 2000);
            return false
        }
    }).on('hide.bs.collapse', function (e) {

        return completedJoinSteps >= $(e.target).data('step')
    }).on('hidden.bs.collapse', function (e) {
        $(e.target).find('.toggle-cog').removeClass('fa-cog fa-spin').addClass('fa-check');
    });

    $('#accordion .list-plans input')
        .on('change', function () {
            $(this).next('label').find('.toggle-cog').addClass('fa-cog fa-spin').removeClass('fa-check');
            $('#next-step').removeClass('active');

            setTimeout(function () {
                completedJoinSteps = 2;
                $("#collapseTwo").collapse('show');
            }, 1000);
        });

    $('#accordion #next-step').on('click', function () {
        $(this).addClass('active');

        setTimeout(function () {
            completedJoinSteps = 3;
            $("#collapseThree").collapse('show');
        }, 1000);
    });
});
