document.addEventListener('turbolinks:load', function () {
    var platform_loaded;
    var tracked;
    if ($('.open-popup-link').length) {
        $('.open-popup-link').magnificPopup({
            type: 'inline',
            closeBtnInside: false,
            closeMarkup: '',
            fixedContentPos: false,
            callbacks: {
                beforeOpen: function () {
                    $("body").addClass("mfp-popup-open");

                    if ($('.track-play').length && !tracked) {
                        // $.post($('.track-play').data('track-url'));
                        $.ajax({
                            type: 'post',
                            data: "authenticity_token=" + encodeURIComponent($('meta[name="csrf-token"]').attr('content')),
                            url: $('.track-play').data('track-url')
                        })
                        tracked = true;
                    }
                },
                open: function () {
                    link = $(this._lastFocusedEl);
                    popup_name = link.data('mfp-src');
                    popup = $(popup_name);
                    index = link.data('slick-goto');

                    switch (popup_name) {
                        case "#social-popup":
                            platform = link.data('platform');
                            if (platform !== platform_loaded) {
                                popup.html(''); // empty to reduce flicker when sth is in popup
                                // manually show the preloader after n msec
                                setTimeout(function () {
                                    $('.mfp-preloader').show();
                                }, 800);

                                platform_loaded = platform;
                                $.get(link.attr('href'), function () {
                                    popup.find('.post-text').each(function () {
                                        $(this).html(twitter.autoLink(this.textContent));
                                    });

                                    popup.find(".soc-carousel").slick({
                                        rows: 0, // https://github.com/kenwheeler/slick/issues/3207#issuecomment-342781958
                                        prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="icomoon icomoon-arrow-left"></i></button>',
                                        nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="icomoon icomoon-arrow-right"></i></button>',
                                        initialSlide: index
                                    });

                                    $('.mfp-close').on('click', function (event) {
                                        event.preventDefault();
                                        $.magnificPopup.close();
                                    });
                                });
                            } else {
                                popup.find(".soc-carousel").slick({
                                    rows: 0, // https://github.com/kenwheeler/slick/issues/3207#issuecomment-342781958
                                    prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="icomoon icomoon-arrow-left"></i></button>',
                                    nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="icomoon icomoon-arrow-right"></i></button>',
                                    initialSlide: index
                                });
                            }
                            break;
                        default:
                            popup.find(".slider-for, .slider-nav").slick({
                                rows: 0, // https://github.com/kenwheeler/slick/issues/3207#issuecomment-342781958
                                prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="icomoon icomoon-arrow-left"></i></button>',
                                nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="icomoon icomoon-arrow-right"></i></button>',
                                initialSlide: index
                            });

                            $('.mfp-close').on('click', function (event) {
                                event.preventDefault();
                                $.magnificPopup.close();
                            });
                    }
                },
                beforeClose: function () {
                    $('.slider-for.slick-initialized, .slider-nav.slick-initialized, .soc-carousel.slick-initialized').slick("unslick");
                    $("body").removeClass("mfp-popup-open");
                }
            }
        });
    }

    $("#videos-popup").find(".slider-for").on('init', function (event, slick) {
        setTimeout(function(){
            $(slick['$slides'][slick.currentSlide]).find('.video-js').each(function () {
                video = this
                setTimeout(function(){
                    videojs(video).play();
                }, 100);
            });
        }, 100);
    }).on('afterChange', function (event, slick, currentSlide) {
        $(slick['$slides'][currentSlide]).find('.video-js').each(function () {
            videojs(this).play();
        });
    }).on('destroy', function () {
        $('.video-js').each(function () {
            videojs(this).pause();
        });
    });

    $(document).on("click", ".btn-comment--social-popup", function () {
        $(this).toggleClass('active');
        $(this).closest('.social-popup--info').toggleClass('active');
    });
});
