document.addEventListener('turbolinks:load', function () {
    // var len = $(".webcam-user .webcam-user__item").length;
    // var intervalCounter = 1;
    // setInterval(function () {
    //     intervalCounter++;
    //     if (intervalCounter > len) intervalCounter = 1;
    //     $(".webcam-user__item").removeClass("active");
    //     $(".webcam-user__item").eq(intervalCounter - 1).addClass("active");
    // }, 8000);

    $('.to-top').on('click', function () {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
        return false
    });

    // $('.popup-embed').magnificPopup({
    //     disableOn: 700,
    //     type: 'iframe',
    //     mainClass: 'mfp-fade',
    //     removalDelay: 160,
    //     preloader: true,
    //     fixedContentPos: false,
    //     gallery: {
    //         enabled: true
    //     },
    // });
    //
    // $('.popup-image').magnificPopup({
    //     type: 'image',
    //     mainClass: 'mfp-fade',
    //     gallery: {
    //         enabled: true,
    //         navigateByImgClick: true,
    //         preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
    //     },
    // });
    //
    // $('.open-photos_popup').magnificPopup({
    //     type: 'inline',
    //     closeBtnInside: true,
    //     //closeMarkup: '<button title="%title%" type="button" class="mfp-close btn btn-popup"><span class="option-icon"><i class="icomoon icomoon-delete"></i><i class="icomoon icomoon-delete"></i></span></button>',
    //     midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    //     callbacks: {
    //         // beforeOpen: function () {
    //         //     $("body").addClass("mfp-popup-open");
    //         // },
    //         open: function () {
    //             index = $(this._lastFocusedEl).data('slick-goto');
    //
    //             $(".slider-for").slick({
    //                 prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="icomoon icomoon-arrow-left"></i></button>',
    //                 nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="icomoon icomoon-arrow-right"></i></button>',
    //                 lazyLoad: 'ondemand',
    //                 fade: true,
    //                 initialSlide: index
    //             });
    //
    //             $(".slider-nav").slick({
    //                 prevArrow: false,
    //                 nextArrow: false,
    //                 initialSlide: index
    //             });
    //
    //             $('.mfp-close').on('click', function (event) {
    //                 event.preventDefault();
    //                 $.magnificPopup.close();
    //             });
    //         },
    //         beforeClose: function () {
    //             $('.slick-initialized').slick("unslick");
    //             $("body").removeClass("mfp-popup-open");
    //         }
    //     }
    // });

    $('.form-control').change(function () {
        var $this = $(this);
        var $thisWrap = $this.parent('.form-group');
        if ($this.val() !== "") {
            $thisWrap.addClass('noEmpty');
        } else {
            $thisWrap.removeClass('noEmpty');
        }
    });

    $(".item-carousel[data-start-video]").on('click', function () {
        if (el = $('.video-js')[0]) {
            el.player.play();
        }
        // $('.video-js.vjs-paused').each(function() {
        //     this.player.pause()
        // })
    });

    $(".item-carousel[data-stop-video]").on('click', function () {
        $('.video-js.vjs-playing').each(function() {
            this.player.pause()
        })
    });

    $('#btn-comment-collapse').on('click', function () {
        $(this).toggleClass('active');
    });

    // $('#btn-opened-tip').on('click', function (e) {
    //     e.preventDefault();
    //     $(this).addClass('active');
    //     $('.actions-group').addClass('hidden');
    //     $('.tip-popup').addClass('active');
    // });
    //
    // $('#btn-closed-tip').on('click', function (e) {
    //     e.preventDefault();
    //     $('#btn-opened-tip').removeClass('active');
    //     $('.actions-group').removeClass('hidden');
    //     $('.tip-popup').removeClass('active');
    // });

    $('#btn-hide-option').click(function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('.wrap-photo-popup-option').toggleClass('active');
    });

    $('.to-be-animated').addClass('animate');

    $('.img-to-change').on('click', function () {
        el = $(this)
        $('#' + el.data('target')).attr('src', el.data('src'))
    });
});

document.addEventListener('turbolinks:before-cache', function () {
    $('.slick-initialized').slick('unslick');
    $('.to-be-animated').removeClass('animate');
});
