var set_cart_num;

set_cart_num = function() {
  var cart, json;
  if ($('.cart-num').length) {
    cart = Cookies.get('cart');
    if (cart !== void 0) {
      json = JSON.parse(cart);
      $('.cart-num').html(json.length);
      if (json.length !== 0) {
        $('.navbar .cart-link').removeClass('d-none');
      }
      return json.forEach(function(el) {
        el = $('a.buy[data-collection=' + el + ']').find('strong');
        return el.html(el.data('alt'));
      });
    }
  }
};

$(document).on('turbolinks:load render_async_load', function() {
  var cart_block, result_block;
  set_cart_num();
  if ($('.cart_pay_via, .donation_pay_via').length) {
    $('.cart_pay_via, .donation_pay_via').find('input:radio').change(function() {
      if ($(this).data('email-required')) {
        $('#email_required').show();
        return $('#cart_email, #donation_email').focus();
      } else {
        return $('#email_required').hide();
      }
    });
    $('.cart_pay_via, .donation_pay_via').find('input:radio:checked').trigger('change');
  }
  if ($('.cart-block').length) {
    cart_block = $('.cart-block');
    result_block = $('.cart-result');
  }
  $("a.buy").click(function() {
    var cart, data, id;
    id = $(this).data('collection');
    cart = Cookies.get('cart');
    if (cart === void 0) {
      cart = [id];
    } else {
      cart = JSON.parse(cart);
      if (jQuery.inArray(id, cart) >= 0) {
        if ($(this).data('url')) {
          Turbolinks.visit($(this).data('url'));
        } else {
          cart_block[0].click();
        }
        return false;
      } else {
        cart.unshift(id);
      }
    }
    Cookies.set('cart', JSON.stringify(cart), {
      expires: 365
    });
    if (cart_block !== void 0) {
      cart_block[0].click();
    }
    if ($(this).find('strong').length) {
      $(this).find('strong').html($(this).find('strong').data('alt'));
      if ((data = $(this).find('span').data('alt'))) {
        $(this).find('span').html(data);
      }
      $('#navbar').find('#cart').removeClass('d-none');
    }
    set_cart_num();
    return false;
  });
  if (cart_block !== void 0) {
    cart_block.on("ajax:success", function(event) {
      var data, ref, status, xhr;
      ref = event.detail, data = ref[0], status = ref[1], xhr = ref[2];
      result_block.html(xhr.responseText);
      return result_block.addClass('active');
    }).on("ajax:error", function(event) {
      result_block.html("<p>ERROR</p>");
      return result_block.addClass('active');
    });
    result_block.on('click', '.cart__result__delete, .cart-result-delete', function() {
      var cart, id;
      id = $(this).data('collection');
      cart = Cookies.get('cart');
      if (cart !== void 0) {
        cart = JSON.parse(cart);
        if (jQuery.inArray(id, cart) >= 0) {
          cart = jQuery.grep(cart, function(value) {
            return value !== id;
          });
          Cookies.set('cart', JSON.stringify(cart), {
            expires: 365
          });
        }
      }
      if (cart.length === 0) {
        result_block.removeClass('active');
      } else {
        cart_block[0].click();
      }
      set_cart_num();
      return false;
    });
    return result_block.on('click', '.cart__close, .btn--cart__close, .btn-close-cart-result, .btn-cart-close, .cart-result--total--sum a', function() {
      return result_block.removeClass('active');
    });
  }
});
