document.addEventListener('turbolinks:load', function () {
    search = $("#q").autocomplete({
        source: $("#q").closest('form').attr('action'),
        minLength: 3,
        appendTo: '.searchform',
        // select: function (event, ui) {
        //     Turbolinks.visit(ui.item.id);
        // }

    }).off('blur').on('blur', function () {
        // prevent closing of autocomplete when window loses focus
        // https://stackoverflow.com/a/13233221
        if (document.hasFocus()) {
            $('ul.ui-autocomplete').hide();
        }
    });

    if (search.length) {
        search.data("ui-autocomplete")._renderMenu = function (ul, items) {
            var that = this;
            $.each(items, function (index, item) {
                that._renderItemData(ul, item);
            });
            $(ul).addClass("dropdown-menu");
        };

        search.data("ui-autocomplete")._renderItem = function (ul, item) {
            var markup = [
                '<a class="dropdown-item" href="' + item.url + '">',
                '  <span class="d-flex align-items-center search-item">',
                '      <img src="' + item.image + '" />',
                // '      <i class="icomoon icomoon-search"></i>',
                // '      <i class="icomoon icomoon-play-round"></i>',
                // '      <i class="icomoon icomoon-camera"></i>',
                '      <span class="name-model">' + item.name + '</span>',
                '  </span>',
                '</a>'
            ];

            return $("<li>").append(markup.join('')).appendTo(ul);
        };
    }

    $(".btn-searchview").on("click", function () {
        $("body").addClass("searchopen-navbar");
    });

    $(".navbar #searchview-close").on("click", function () {
        $("body").removeClass("searchopen-navbar").addClass("searchhide-navbar");
        setTimeout(function () {
            $("body").removeClass("searchhide-navbar");
        }, 500);
    });
});
